<template>
  <div>
    <v-row class="sticky-filters">
      <v-col
        v-show="filters.credentials.value || filters.type.value"

        cols="2"
      >
        <base-selector
          :items="statuses"
          label="Task status"
          :initial-value="filters.status"
          @select="selectStatus"
        />
      </v-col>
      <v-col cols="2">
        <base-task-type-selector
          :initial-value="filters.type"
          @select="selectType"
        />
      </v-col>
      <v-col
        v-show="!filters.credentials.value"
        cols="2"
      >
        <base-workers-selector
          :initial-value="{_id: filters.worker.value}"
          @select="selectWorkers"
        />
      </v-col>

      <v-col
        v-show="!filters.worker.value"
        cols="2"
      >
        <base-credentials-selector
          :initial-value="{_id: filters.credentials.value}"
          @select="selectCredentials"
        />
      </v-col>
      <v-col cols="2">
        <base-workflows-selector
          :initial-value="{_id: filters.workflow.value}"
          @select="selectWorkflows"
        />
      </v-col>

      <v-col cols="2">
        <v-btn
          v-show="selectedTask._id"
          depressed
          color="error"
          class="mt-2"
          @click="deleteTask"
        >
          Delete task
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn
          v-show="selectedTask._id"
          depressed
          color="secondary"
          class="mt-2"
          @click="retryTask"
        >
          Retry task
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-timeline clipped>
          <div
            v-for="(task, i) of tasksList"
            :key="task._id"
            @click="selectTask(i)"
          >
            <task-item
              :active="selectedTask._id === task._id"
              :task="task"
            />
          </div>
        </v-timeline>
      </v-col>
      <v-col cols="6">
        <prism-editor
          ref="editor"
          :value="selectedTaskForEditor"
          :readonly="true"
          class="my-editor"
          :highlight="highlighter"
        />
      </v-col>
      <v-col
        v-if="error"
        cols="6"
      >
        <v-alert
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-col>
      <v-progress-linear
        v-if="loading"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      />
    </v-row>
  </div>
</template>

<script>
  import TaskItem from '@/components/tasksPage/TaskItem';
  import { mapGetters } from 'vuex';

  import { lazyLoadMixin } from '@/mixins/lazyLoadMixin';
  import { addLinksMixin } from '@/mixins/addLinksMixin';
  import { queryFiltersMixin } from '@/mixins/QueryFiltersMixin';

  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css';

  // import highlighting library (you can use any library you want just return html string)
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
  import BaseWorkersSelector from '@/components/base/selectors/BaseWorkersSelector.vue';
  import BaseCredentialsSelector from '@/components/base/selectors/BaseCredentialsSelector.vue';
  import BaseWorkflowsSelector from '@/components/base/selectors/BaseWorkflowsSelector.vue';
  import BaseTaskTypeSelector from '@/components/base/selectors/BaseTaskTypeSelector.vue';
  import BaseSelector from '@/components/base/selectors/BaseSelector.vue';

  export default {
    name: 'TasksPage',
    components: {

      TaskItem,
      PrismEditor,
      BaseWorkersSelector,
      BaseCredentialsSelector,
      BaseWorkflowsSelector,
      BaseTaskTypeSelector,
      BaseSelector,

    },
    mixins: [lazyLoadMixin, queryFiltersMixin, addLinksMixin],
    data () {
      return {
        vuexFiltersAction: 'tasks/SET_FILTERS',
        selectedTaskForEditor: '{test: "kek"}',
        selectedTask: {},
        error: '',
        statuses: [
          { label: 'Task status', value: '' },
          { label: 'Done', value: 'done' },
          { label: 'Failed', value: 'failed' },
          { label: 'WIP', value: 'wip' },
          { label: 'New', value: 'new' },
          { label: 'Celery', value: 'celery' },
          { label: 'Pending', value: 'pending' },
        ],
      };
    },
    computed: {
      ...mapGetters({
        tasksList: 'tasks/tasksList',
        filters: 'tasks/filters',
      }),
    },
    async created () {
      if (Object.keys(this.$route.query).length) {
        this.setFiltersByQuery(this.vuexFiltersAction);
      }
      await this.loadTasks();
    },
    methods: {
      async selectStatus (newStatus) {
        this.$store.dispatch(this.vuexFiltersAction, { status: newStatus });
        this.loadTasks();
      },
      async selectCredentials (newCred) {
        if (!this.filters.worker || !this.filters.worker.value) {
          this.$store.dispatch(this.vuexFiltersAction, { workflow: '', credentials: { value: newCred._id } });
          await this.$store.dispatch('workflows/SET_NO_STATS_WORKFLOWS', newCred._id);
          this.loadTasks();
        }
      },
      async selectWorkflows (newWorkflow) {
        this.$store.dispatch(this.vuexFiltersAction, { workflow: { value: newWorkflow._id } });
        this.loadTasks();
      },
      async selectWorkers (newWorker) {
        this.$store.dispatch(this.vuexFiltersAction, { workflow: '', worker: { value: newWorker._id } });
        this.loadTasks();
      },
      async selectType (newType) {
        newType = {
          value: newType.value,
          label: newType.label,
        };
        this.$store.dispatch(this.vuexFiltersAction, { type: newType });
        this.loadTasks();
      },
      async loadTasks () {
        try {
          this.setQueryByFilters(this.filters);
          this.loading = true;

          const { status, credentials, type } = this.filters;
          if (status.value && !(credentials.value || type.value)) {
            this.$store.dispatch(this.vuexFiltersAction, { status: { value: '' } });
          }
          await this.setTaskList();
        } catch (e) {
          console.error(e);
          this.error = e.message;
        } finally {
          this.loading = false;
        }
      },
      async deleteTask () {
        await this.$store.dispatch('tasks/DELETE_TASK', this.selectedTask._id);
        this.selectedTask = {};
        this.selectedTaskForEditor = '{test: "kek"}';
      },
      async retryTask () {
        await this.$store.dispatch('tasks/RETRY_TASK', this.selectedTask._id);
      },
      handleScroll: async function () {
        const bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 200 >=
          document.documentElement.offsetHeight;
        if (bottomOfWindow && this.canLoadMore && !this.loading) {
          this.loading = true;
          const count = this.tasksList.length;
          await this.setTaskList(count);
          this.loading = false;
          this.canLoadMore = this.tasksList.length > count;
        }
      },
      async setTaskList (count) {
        await this.$store.dispatch('tasks/LOAD_TASKS_LIST', count);
      },
      highlighter (code) {
        return highlight(code, languages.js); // returns html
      },
      selectTask (index) {
        this.selectedTask = this.tasksList[index];
        const selectedTask = { ...this.selectedTask };
        delete selectedTask.links;
        this.selectedTaskForEditor = JSON.stringify(
          selectedTask,
          null,
          '\t'
        ).replace(/"([^"]+)":/g, '$1:');
        if (this.selectedTask.links) {
          setTimeout(() => this.addLinks(this.selectedTask.links));
        }
      },
    },
  };
</script>

<style>
  .my-editor {
    background: #2d2d2d;
    color: #ccc;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 25px;

    position: fixed;
    top: 150px;
    height: 75vh;
    width: 60vw;
    z-index: 1;
    white-space: pre-wrap;
  }

  .prism-editor__textarea:focus {
    outline: none;
  }
  .sticky-filters{
    position: sticky;
    top:60px;
    margin-left: 8px;
    background: #fff;
    z-index: 3;

  }
  .sticky-filters .col-2{
    max-width: 13%;
  }
</style>
